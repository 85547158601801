* {
  padding: 0;
  margin: 0;
}

.body {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.container {
  background-image: url("../images/mountain.jpg");
  height: 80vh;
  background-size: cover;
  position: relative;
}

.leftSide {
  position: absolute;
  left: 0;
  background-color: white;
  height: 100vh;
  width: 600px;
  opacity: 0.9;
}

.rightSide {
  position: absolute;
  right: 0;
  background-color: white;
  height: 100vh;
  width: 600px;
  opacity: 0.9;
}

.leftNav {
  display: flex;
  padding: 50px;
}

.navItem {
  margin-right: 40px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}

.rightNav {
  display: flex;
  padding: 50px;
  margin-left: 50px;
}

.summary {
  font-weight: 500;
  font-size: 12px;
  width: 235px;
  padding: 50px;
}

.exploreText {
  font-weight: 300;
  font-size: 12px;
  cursor: pointer;
  margin-left: 50px;
  margin-top: -40px;
}

.mission {
  position: absolute;
  bottom: 0;
  background-color: #e4eded;
  width: 100%;
  height: 20vh;
  /* overflow-x: auto; */
}

.travelImages {
  display: flex;
}

.travelExp {
  width: 200px;
  font-size: 20px;
  padding: 20px;
  margin-left: 50px;
  font-weight: 500;
}

.missionText {
  position: absolute;
  top: 0;
  font-size: 12px;
  font-weight: 300;
  margin-left: 70px;
}

.travelImg {
  margin-left: 50px;
}

.bar-default {
  background-color: black;
  width: 30px;
  height: 5px;
  border-radius: 70px;
  margin-bottom: 2px;
  transition: 0.4s;
  cursor: pointer;
}

.dropdownMenu {
  display: none;
}

.image1 {
  height: 125px;
  width: 200px;
}

.avatar {
  display: flex;
  padding: 50px;
  margin-top: 100px;
  margin-left: 100px;
}

.avatar1 {
  height: 75px;
  width: 75px;
  border-radius: 75px;
  border: 2px solid black;
}

.avatar2 {
  height: 75px;
  width: 75px;
  border-radius: 75px;
  border: 2px solid black;
  margin-left: -20px;
}

.avatar3 {
  height: 75px;
  width: 75px;
  border-radius: 75px;
  border: 2px solid black;
  margin-left: -20px;
}

.avatarImage {
  height: 75px;
  width: 75px;
  border-radius: 75px;
}

.dropdown {
  position: absolute;
  right: 50px;
  top: 60px;
  width: 200px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 8px 12px;
}

li:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.travelDiv {
  position: absolute;
  left: 20px;
  top: 6px;
}

.travelImage {
  height: 50px;
  width: 50px;
}

.button {
  height: 30px;
  width: 100px;
  border: 1px solid black;
  border-radius: 50px;
  background-color: black;
  margin-left: 70px;
  cursor: pointer;
  color: white;
}

.buttonText {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-top: 5px;
}
.images {
  display: flex;
}

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 20px;
  border: 2px solid black;
  border-radius: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 3px;
  background-color: red;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: white;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 1200px) {
  .body {
    position: relative;
    height: 100vh;
    width: 100vh;
  }
  .leftNav {
    display: none;
  }
  .rightNav {
    display: none;
  }
  .leftNav {
    display: none;
  }
  .rightNav {
    display: none;
  }
  .container {
    height: 50vh;
  }
  .mission {
    position: relative;
    bottom: 0px;
    height: 12vh;
  }
  .dropdownMenu {
    display: block;
    padding: 30px;
  }
  .leftSide {
    background-color: revert;
  }
  .rightSide {
    background-color: revert;
  }
  .summary {
    background-color: revert;
  }
  .travelImages {
    display: block;
  }
  .travelImg {
    margin-left: 0px;
    margin-top: 40px;
    float: left;
  }
  .image1 {
    height: auto;
    width: 100vh;
  }
  .menu {
    position: absolute;
    right: 80px;
  }
  .images {
    display: block;
    margin-top: 20px;
    /* padding: 20px; */
  }
  .button {
    margin-top: 30px;
  }
  .experience {
    display: flex;
  }
  .avatar {
    display: flex;
    position: absolute;
    top: 100px;
    right: 20px;
    margin-left: 100px;
  }
  .avatar {
    display: none;
  }
}

@media screen and (max-width: 600px) {
}
